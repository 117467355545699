import {
  createEmbedBlockSchema,
  EmbedHtmlStyles,
  type EmbedHtmlBlockProps,
} from '@blocksuite/affine-model';
import { EmbedHtmlModel } from '@blocksuite/affine-model';
import { css, html } from 'lit';
import { query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { type StyleInfo, styleMap } from 'lit/directives/style-map.js';

import { EmbedBlockComponent } from '@blocksuite/affine/blocks';
// import { EmbedBlockComponent } from '../common/embed-block-element.js';
// import { HtmlIcon, styles } from './styles.js';
const FullScreenIcon = html`<svg
  t="1734331988472"
  class="icon"
  viewBox="0 0 1024 1024"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  p-id="1678"
  width="20"
  height="20"
>
  <path
    d="M864 128H672c-17.7 0-32 14.3-32 32s14.3 32 32 32h114.7L585.4 393.4c-12.5 12.5-12.5 32.8 0 45.3 6.2 6.2 14.4 9.4 22.6 9.4s16.4-3.1 22.6-9.4L832 237.3V352c0 17.7 14.3 32 32 32s32-14.3 32-32V160c0-17.7-14.3-32-32-32zM393.4 585.4L192 786.7V672c0-17.7-14.3-32-32-32s-32 14.3-32 32v192c0 17.7 14.3 32 32 32h192c17.7 0 32-14.3 32-32s-14.3-32-32-32H237.3l201.4-201.4c12.5-12.5 12.5-32.8 0-45.3-12.6-12.4-32.8-12.4-45.3 0.1z"
    p-id="1679"
    fill="#7a7a7a"
  ></path>
</svg>`;

const styles = css`
  .affine-embed-html-block {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    position: relative;
    border-radius: 12px;
    border: 1px solid var(--affine-background-tertiary-color);

    opacity: var(--add, 1);
    background: var(--affine-background-primary-color);
    user-select: none;
  }
  .affine-embed-html-full-screen-icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 10;
  }

  .affine-embed-html {
    flex-grow: 1;
    width: 100%;
    opacity: var(--add, 1);
  }

  .affine-embed-html img,
  .affine-embed-html object,
  .affine-embed-html svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px 4px var(--1, 0px) var(--1, 0px);
  }

  .affine-embed-html-iframe-container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0px 0px;
    box-shadow: var(--affine-shadow-1);
    overflow: hidden;
  }

  .embed-html-block-iframe-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .embed-html-block-iframe-wrapper > iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .embed-html-block-iframe-wrapper affine-menu {
    min-width: 296px;
  }

  .embed-html-block-iframe-wrapper affine-menu .settings-header {
    padding: 7px 12px;
    font-weight: 500;
    font-size: var(--affine-font-xs);
    color: var(--affine-text-secondary-color);
  }

  .embed-html-block-iframe-wrapper > embed-html-fullscreen-toolbar {
    visibility: hidden;
  }

  .embed-html-block-iframe-wrapper:fullscreen > embed-html-fullscreen-toolbar {
    visibility: visible;
  }

  .affine-embed-html-iframe-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .affine-embed-html-iframe-overlay.hide {
    display: none;
  }

  .affine-embed-html-title {
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;

    padding: var(--1, 0px);
    border-radius: var(--1, 0px);
    opacity: var(--add, 1);
  }

  .affine-embed-html-title-icon {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
  }

  .affine-embed-html-title-icon img,
  .affine-embed-html-title-icon object,
  .affine-embed-html-title-icon svg {
    width: 20px;
    height: 20px;
    fill: var(--affine-background-primary-color);
  }

  .affine-embed-html-title-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--affine-text-primary-color);

    font-family: var(--affine-font-family);
    font-size: var(--affine-font-sm);
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
`;
export class EmbedHtmlCustomBlockComponent extends EmbedBlockComponent<EmbedHtmlModel> {
  static override styles = styles;

  override _cardStyle: (typeof EmbedHtmlStyles)[number] = 'html';

  protected _isDragging = false;

  protected _isResizing = false;

  close = () => {
    document.exitFullscreen().catch(console.error);
  };

  protected embedHtmlStyle: StyleInfo = {};

  open = () => {
    this.iframeWrapper?.requestFullscreen().catch(console.error);
  };

  refreshData = () => {};

  private _handleDoubleClick(event: MouseEvent) {
    event.stopPropagation();
    this.open();
  }

  private _selectBlock() {
    const selectionManager = this.host.selection;
    const blockSelection = selectionManager.create('block', {
      blockId: this.blockId,
    });
    selectionManager.setGroup('note', [blockSelection]);
  }

  protected _handleClick(event: MouseEvent) {
    event.stopPropagation();
    this._selectBlock();
  }

  override connectedCallback() {
    super.connectedCallback();

    // this is required to prevent iframe from capturing pointer events
    this.disposables.add(
      this.std.selection.slots.changed.on(() => {
        this._isSelected =
          !!this.selected?.is('block') || !!this.selected?.is('surface');

        this._showOverlay =
          this._isResizing || this._isDragging || !this._isSelected;
      })
    );
    // this is required to prevent iframe from capturing pointer events
    this.handleEvent('dragStart', () => {
      this._isDragging = true;
      this._showOverlay =
        this._isResizing || this._isDragging || !this._isSelected;
    });

    this.handleEvent('dragEnd', () => {
      this._isDragging = false;
      this._showOverlay =
        this._isResizing || this._isDragging || !this._isSelected;
    });
  }

  private _showFullScreenIcon = false;

  private _handleMouseEnter() {
    this._showFullScreenIcon = true;
  }

  private _handleMouseLeave() {
    this._showFullScreenIcon = false;
  }
  override renderBlock(): unknown {
    const { style } = this.model;

    this._cardStyle = style;

    const titleText = 'Basic HTML Page Structure';

    // sandbox="allow-scripts"
    // scrolling="no"
    return this.renderEmbed(() => {
      if (!this.model.html) {
        return html` <div class="affine-html-empty">Empty</div>`;
      }
      return html`
        <div
          class=${classMap({
            'affine-embed-html-block': true,
            selected: this._isSelected,
          })}
          style=${styleMap(this.embedHtmlStyle)}
          @click=${this._handleClick}
          @dblclick=${this._handleDoubleClick}
          @mouseenter=${this._handleMouseEnter}
          @mouseleave=${this._handleMouseLeave}
        >
          <div
            @click=${this._handleDoubleClick}
            class="affine-embed-html-full-screen-icon"
            style=${styleMap({
              display: this._showFullScreenIcon ? 'block' : 'none',
            })}
          >
            ${FullScreenIcon}
          </div>
          <div class="affine-embed-html">
            <div class="affine-embed-html-iframe-container">
              <div class="embed-html-block-iframe-wrapper" allowfullscreen>
                <iframe
                  class="embed-html-block-iframe"
                  src=${this.model.html}
                ></iframe>
                <embed-html-fullscreen-toolbar
                  .embedHtml=${this}
                ></embed-html-fullscreen-toolbar>
              </div>

              <div
                class=${classMap({
                  'affine-embed-html-iframe-overlay': true,
                  hide: !this._showOverlay,
                })}
              ></div>
            </div>
          </div>
        </div>
      `;
    });
  }

  @state()
  protected accessor _isSelected = false;

  @state()
  protected accessor _showOverlay = true;

  @query('.embed-html-block-iframe-wrapper')
  accessor iframeWrapper!: HTMLDivElement;
}

const defaultEmbedHtmlProps: EmbedHtmlBlockProps = {
  style: EmbedHtmlStyles[0],
  caption: null,
  html: undefined,
  design: undefined,
};

export const EmbedHtmlCustomBlockSchema = createEmbedBlockSchema({
  name: 'html-custom',
  version: 1,
  toModel: () => new EmbedHtmlModel(),
  props: (): EmbedHtmlBlockProps => defaultEmbedHtmlProps,
});

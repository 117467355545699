import '../../_common/components/ask-ai-button';

import {
  type AffineFormatBarWidget,
  toolbarDefaultConfig,
} from '@blocksuite/affine/blocks';
import { html, type TemplateResult } from 'lit';

import { AIItemGroups } from '../../_common/config';
import { getEdgelessCopilotWidget } from '../../utils/edgeless';
import { getCopilotSelectedElems } from '../../utils/selection-utils';

export function setupFormatBarAIEntry(formatBar: AffineFormatBarWidget) {
  toolbarDefaultConfig(formatBar);
  formatBar.addRawConfigItems(
    [
      {
        type: 'custom' as const,
        render(formatBar: AffineFormatBarWidget): TemplateResult | null {
          return html`<ask-ai-button
            .host=${formatBar.host}
            .actionGroups=${AIItemGroups}
            .toggleType=${'hover'}
          ></ask-ai-button>`;
        },
      },
      { type: 'divider' },
      // {
      //   type: 'custom' as const,

      //   render(formatBar: AffineFormatBarWidget): TemplateResult | null {
      //     const edgelessCopilot = getEdgelessCopilotWidget(formatBar.host);
      //     const selectedElements = getCopilotSelectedElems(formatBar.host);
      //     const type = selectedElements?.[0]?.flavour;
      //     return type === 'affine:attachment'
      //       ? html`<div>Show Detail</div>`
      //       : html``;
      //   },
      // },
    ],
    0
  );
}

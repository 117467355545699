import type {
  Cell,
  Column,
  DatabaseBlockModel,
  DocMode,
  EdgelessRootBlockComponent,
} from '@blocksuite/affine/blocks';
import type {
  AffineEditorContainer,
  DocTitle,
  EdgelessEditor,
  PageEditor,
} from '@blocksuite/affine/presets';
import { BlockModel, type Doc, Slot } from '@blocksuite/store';

import clsx from 'clsx';
import type React from 'react';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { BlocksuiteDocEditor, BlocksuiteEdgelessEditor } from './lit-adaper';
import * as styles from './styles.css';
import { getEdgelessRootFromEditor } from '@affine/core/blocksuite/presets/ai/utils/selection-utils';
import {
  assertExists,
  Bound,
  getElementsBound,
  Point,
} from '@blocksuite/global/utils';
import { DatabaseBlockDataSource, NoteBlockModel } from '@blocksuite/blocks';
import DSocket from './websocket';
import type { EditorHost } from '@blocksuite/block-std';
import { markdownToSnapshot } from '../../../blocksuite/presets/ai/utils/markdown-utils';

let gif = require('./aithinking.gif');
import './custom.css';
import { CopilotSelectionControllerCustom } from './copilot-custom';
import useEventBus from '../../../eventBus/hooks/index.js';
import {
  insertPositionToIndex,
  type InsertToPosition,
} from '@blocksuite/affine-shared/utils';
import { Modal, Typography } from 'antd';
import { aiIslandBtn } from '../../pure/ai-island/styles.css';
const { Paragraph } = Typography;
import { ZipTransformer } from '@blocksuite/blocks';

import CardDetailModal from './components/card-detail-modal';
import NotesDetailModal from './components/notes-detail-modal';
import { useServices, WorkspaceService } from '@toeverything/infra';
import axios from 'axios';
import EditModal from './components/edit-modal';
import { Text } from '@blocksuite/store';
import { constructRootChatBlockMessages } from '@affine/core/blocksuite/presets/ai/_common/chat-actions-handle';

interface BlocksuiteEditorContainerProps {
  page: Doc;
  mode: DocMode;
  shared?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

// mimic the interface of the webcomponent and expose slots & host
type BlocksuiteEditorContainerRef = Pick<
  (typeof AffineEditorContainer)['prototype'],
  'mode' | 'doc' | 'slots' | 'host'
> &
  HTMLDivElement;

export const BlocksuiteEditorContainer = forwardRef<
  AffineEditorContainer,
  BlocksuiteEditorContainerProps
>(function AffineEditorContainer(
  { page, mode, className, style, shared },
  ref
) {
  const rootRef = useRef<HTMLDivElement>(null);
  const docRef = useRef<PageEditor>(null);
  const docTitleRef = useRef<DocTitle>(null);
  const edgelessRef = useRef<EdgelessEditor>(null);

  const slots: BlocksuiteEditorContainerRef['slots'] = useMemo(() => {
    return {
      editorModeSwitched: new Slot(),
      docUpdated: new Slot(),
    };
  }, []);

  useLayoutEffect(() => {
    slots.docUpdated.emit({ newDocId: page.id });
  }, [page, slots.docUpdated]);

  /**
   * mimic an AffineEditorContainer using proxy
   */
  const affineEditorContainerProxy = useMemo(() => {
    const api = {
      slots,
      get page() {
        return page;
      },
      get doc() {
        return page;
      },
      get docTitle() {
        return docTitleRef.current;
      },
      get host() {
        return mode === 'page'
          ? docRef.current?.host
          : edgelessRef.current?.host;
      },
      get model() {
        return page.root as any;
      },
      get updateComplete() {
        return mode === 'page'
          ? docRef.current?.updateComplete
          : edgelessRef.current?.updateComplete;
      },
      get mode() {
        return mode;
      },
      get origin() {
        return rootRef.current;
      },
    };

    const proxy = new Proxy(api, {
      has(_, prop) {
        return (
          Reflect.has(api, prop) ||
          (rootRef.current ? Reflect.has(rootRef.current, prop) : false)
        );
      },
      get(_, prop) {
        if (Reflect.has(api, prop)) {
          return api[prop as keyof typeof api];
        }
        if (rootRef.current && Reflect.has(rootRef.current, prop)) {
          const maybeFn = Reflect.get(rootRef.current, prop);
          if (typeof maybeFn === 'function') {
            return maybeFn.bind(rootRef.current);
          } else {
            return maybeFn;
          }
        }
        return undefined;
      },
    }) as unknown as AffineEditorContainer & { origin: HTMLDivElement };

    return proxy;
  }, [mode, page, slots]);

  useImperativeHandle(ref, () => affineEditorContainerProxy, [
    affineEditorContainerProxy,
  ]);

  const handleClickPageModeBlank = useCallback(() => {
    if (shared || page.readonly) return;
    affineEditorContainerProxy.host?.std.command.exec(
      'appendParagraph' as never,
      {}
    );
  }, [affineEditorContainerProxy, page, shared]);

  const fetchFileFromUrl = async (url: string) => {
    // 使用fetch获取URL的内容
    const response = await fetch(url, { referrerPolicy: 'no-referrer' });
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }
    // 将内容转换为Blob
    const blob = await response.blob();
    // 使用Blob和文件名创建File对象
    return new File([blob], '', { type: blob.type });
  };

  const customInsertFromMarkdown = async (
    host: EditorHost,
    markdown: string,
    parent?: string
  ) => {
    const { snapshot, job } = await markdownToSnapshot(markdown, host);
    const snapshots = snapshot.content[0].children;

    const models: BlockModel[] = [];
    for (let i = 0; i < snapshots.length; i++) {
      const blockSnapshot = snapshots[i];
      const model = await job.snapshotToBlock(
        blockSnapshot,
        host.std.doc,
        parent
      );
      if (model) {
        models.push(model);
      }
    }

    return models;
  };
  const insertBelow = async (
    host: EditorHost,
    markdown: string,
    parentId: string
  ) => {
    let models = await customInsertFromMarkdown(host, markdown, parentId)
      .then(() => {
        // const service = edgelessRoot?.service;
        // service.selection.set({
        //   elements: [parentId],
        //   editing: false,
        // });
      })
      .catch(err => {
        console.error(err);
      });
    return models;
  };

  function readImageSize(file: File) {
    return new Promise<{ width: number; height: number }>(resolve => {
      const size = { width: 0, height: 0 };
      const img = new Image();

      img.onload = () => {
        size.width = img.width;
        size.height = img.height;
        URL.revokeObjectURL(img.src);
        resolve(size);
      };

      img.onerror = () => {
        URL.revokeObjectURL(img.src);
        resolve(size);
      };

      img.src = URL.createObjectURL(file);
    });
  }

  const [edgelessRoot, setEdgelessRoot] =
    useState<EdgelessRootBlockComponent>();

  useEffect(() => {
    if (edgelessRef.current) {
      edgelessRef.current.updateComplete
        ?.then(() => {
          if (edgelessRef.current?.host) {
            let root = getEdgelessRootFromEditor(edgelessRef.current.host);
            root.service.registerTool(CopilotSelectionControllerCustom);
            window['edgeRoot'] = root;
            setEdgelessRoot(root);
          }
        })
        .catch(console.error);
    }
  }, [edgelessRef.current]);

  const [inputShow, setInputShow] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [socketInstance, setSocketInstance] = useState();

  useEffect(() => {
    if (mode === 'page') {
      socketInstance?.close();
      setSocketInstance(null);
    }
  }, [mode]);

  const [logsContainerId, setLogsContainerId] = useState({});
  const [connectorIds, setConnectorIds] = useState([]);
  const [gifFile, setGifFile] = useState();

  let firstTime = true;

  useEffect(() => {
    fetchFileFromUrl(gif).then(file => {
      setGifFile(file);
    });
  }, []);

  useEventBus('generate', data => {
    console.log(data, socketInstance, 'acceptData');
    if (mode !== 'page') {
      if (socketInstance?.websock?.readyState === 1) {
        socketInstance.send(JSON.stringify(data), handleMessage);
      } else if (socketInstance?.websock?.readyState === 3 || !socketInstance) {
        let socket = new DSocket(
          `/customsocket/multi-agent/${page.id}`,
          JSON.stringify(data),
          handleMessage
        );
        setSocketInstance(socket);
      }
    }
  });

  useEventBus('rerun', data => {
    console.log(data, socketInstance, 'rerunData');
    if (mode !== 'page') {
      if (socketInstance?.websock?.readyState === 1) {
        socketInstance.send(JSON.stringify(data), handleMessage);
      } else if (socketInstance?.websock?.readyState === 3 || !socketInstance) {
        let socket = new DSocket(
          `/customsocket/multi-agent/${page.id}`,
          JSON.stringify(data),
          handleMessage
        );
        setSocketInstance(socket);
      }
    }
  });

  const [showId, setShowId] = useState<string>('');
  const [modalOpen, setModalOpen] = useState(false);
  useEventBus('showDetail', data => {
    if (mode !== 'page') {
      setShowId(data);
      setModalOpen(true);
    }
  });

  const [notesModalOpen, setNotesModalOpen] = useState(false);
  useEventBus('showCards', data => {
    if (mode !== 'page') {
      setShowId(data);
      setNotesModalOpen(true);
    }
  });

  const [editModalOpen, setEditModalOpen] = useState(false);
  useEventBus('showEdit', data => {
    if (mode !== 'page') {
      setShowId(data);
      setEditModalOpen(true);
    }
  });
  const colorList = [
    '#E6F0FA', // 最浅的天蓝色
    '#FAE6E6', // 最浅的珊瑚粉色
    '#FAF0E6', // 最浅的杏黄色
    '#E6FAF0', // 最浅的薄荷绿色
    '#FAE6F5', // 最浅的淡紫粉色
    '#F0FAE6', // 最浅的黄绿色
    '#E6FAF7', // 最浅的水绿色
    '#F5E6FA', // 最浅的淡紫色
    '#FAF0E6', // 最浅的橙粉色
    '#E6FAFA', // 最浅的淡青色
    '#E6ECFA', // 最浅的浅蓝色
    '#FAE6EC', // 最浅的樱花粉
  ];

  function updateCell(model: DatabaseBlockModel, rowId: string, cell: Cell) {
    const hasRow = rowId in model.cells;
    if (!hasRow) {
      model.cells[rowId] = Object.create(null);
    }
    model.doc.transact(() => {
      model.cells[rowId][cell.columnId] = {
        columnId: cell.columnId,
        value: cell.value,
      };
    });
  }

  function addProperty(
    model: DatabaseBlockModel,
    position: InsertToPosition,
    column: Omit<Column, 'id'> & {
      id?: string;
    }
  ): string {
    const id = column.id ?? model.doc.generateBlockId();
    if (model.columns.some(v => v.id === id)) {
      return id;
    }
    model.doc.transact(() => {
      const col: Column = {
        ...column,
        id,
      };
      model.columns.splice(
        insertPositionToIndex(position, model.columns),
        0,
        col
      );
    });
    return id;
  }

  const createBlockAtPosition = (
    position: { x: number; y: number; w?: number; h?: number },
    collapse: boolean,
    content?: string,
    id?: string,
    actionName?: string,
    backgroundColor?: string
  ) => {
    let containerId = '';
    edgelessRoot?.doc.transact(() => {
      assertExists(edgelessRoot?.doc.root);
      let blockId = '';

      blockId = edgelessRoot?.doc.addBlock(
        'affine:note',
        {
          id,
          xywh: `[${position.x},${position.y},${position?.w || 50},600]`,
          background: backgroundColor || '#fff',
          // displayMode: NoteDisplayMode.EdgelessOnly,
          edgeless: {
            style: {
              borderRadius: 32,
              borderSize: 4,
              borderStyle: 'none',
              shadowType: '--affine-note-shadow-paper',
            },
            collapsedHeight: position?.h || 600,
            collapse,

            // scale: 2,
          },
          generated: true,
          actionName,
        },
        edgelessRoot?.doc.root.id
      );

      // If content is provided, insert it into the new block
      if (content) {
        insertBelow(edgelessRoot?.host, content, blockId);
      }

      // Select the newly created block
      // const service = edgelessRoot?.service;
      // service.selection.set({
      //   elements: [blockId],
      //   editing: false,
      // });
      containerId = blockId;
    });
    return containerId;
  };

  const generateConnector = (sourceId: string, targetId: string) => {
    // 获取源和目标容器的位置和大小
    const sourceElement = edgelessRoot?.service.getElementById(sourceId);
    const targetElement = edgelessRoot?.service.getElementById(targetId);
    console.log(sourceElement?.xywh, sourceId, 'sourceElement.xywh');
    console.log(targetElement?.xywh, targetId, 'targetElement.xywh');
    if (sourceElement && targetElement) {
      const sourceBox = Bound.deserialize(sourceElement.xywh);
      const targetBox = Bound.deserialize(targetElement.xywh);

      const startPoint = {
        x: sourceBox.x + sourceBox.w / 2,
        y: sourceBox.y + sourceBox.h,
      };
      const endPoint = {
        x: targetBox.x + sourceBox.w / 2,
        y: targetBox.y,
      };
      let sourcePosition = sourceBox.y === targetBox.y ? [1, 0.5] : [0.5, 1];
      let targetPosition = sourceBox.y === targetBox.y ? [0, 0.5] : [0.5, 0];

      // 创建连接器
      edgelessRef.current.updateComplete
        .then(() => {
          let connectId = edgelessRoot.service.addElement('connector', {
            strokeWidth: 6,
            shapeStyle: 'Scribbled',
            source: { id: sourceId, position: sourcePosition },
            target: { id: targetId, position: targetPosition },
            controllers: [
              {
                x: startPoint.x + (endPoint.x - startPoint.x) / 2,
                y: startPoint.y,
              },
              {
                x: startPoint.x + (endPoint.x - startPoint.x) / 2,
                y: endPoint.y,
              },
            ],
          });
          try {
            let el = edgelessRoot?.service.getElementById(connectId);
            edgelessRoot?.service.reorderElement(el, 'back');
          } catch (error) {}
          connectorIds.push(connectId);
        })
        .catch(console.error);
    }
  };

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [loadingStr, setLoadingStr] = useState('');
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const messagesEndRef = useRef(null);

  const judgeType = async (id: string) => {
    let response = await axios.get(
      `/back_api/database-services/get_container_detail?guid=${page.id}&container_id=${id}`
    );
    let data = JSON.parse(response?.data?.detail_content || '{}');
    if (data.hasOwnProperty('note_cover')) {
      if (mode !== 'page') {
        setShowId(id);
        setModalOpen(true);
      }

      return;
    } else if (data.hasOwnProperty('cards')) {
      if (mode !== 'page') {
        setShowId(id);
        setNotesModalOpen(true);
      }
      return;
    } else {
      if (mode !== 'page') {
        setShowId(id);
        setEditModalOpen(true);
      }
      return;
    }
  };

  useEffect(() => {
    // 每次messages更新时，滚动到最底部
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [loadingStr]);

  function base64ToUint8Array(base64Str) {
    const byteCharacters = atob(base64Str);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return byteArray;
  }

  const DELAY = 100; // 设置延迟时间（毫秒）

  let messageQueue: [] = [];

  const processMessageQueue = async () => {
    if (messageQueue.length === 0) {
      return;
    }
    let msg = messageQueue.shift();
    console.log(msg, 'msg');
    if (msg.type === 'ping') {
      setTimeout(processMessageQueue, DELAY);
      return;
    }
    let blockId: string = msg.block_id;
    let containerId = msg.container_id;
    let blockType = msg.block_type;
    let upstreamContainerId = msg.upstream_container_id;
    const [originX, originY] = msg?.block_pos || [0, 0];
    let detail = msg.detail || null;
    let actionName = msg?.agent_action_name || '';
    let w = msg?.container_size?.[0] || 400;
    let h = msg?.container_size?.[1] || 600;
    let collapse = msg?.container_size ? false : true;

    if (blockType == 'loading') {
      if (detail.status === 'pending') {
        setLoadingOpen(prev => true);
        setLoadingStr(prev => prev + msg.stream_str);
        setLoadingStatus(prev => 'pending');
      } else if (detail.status === 'finished') {
        setLoadingOpen(prev => false);
        setLoadingStr(prev => '');
        setLoadingStatus(prev => 'finished');
      }
      return;
    }
    if (blockType === 'ShowDetail') {
      // setShowId(containerId);
      // setModalOpen(true);
      judgeType(containerId);
      return;
    }
    if (blockType === 'UpdateStatus') {
      requestAnimationFrame(() => {
        edgelessRoot?.doc.transact(() => {
          const targetBlock = edgelessRoot?.doc.getBlockById(
            containerId
          ) as NoteBlockModel;
          edgelessRoot?.doc.updateBlock(targetBlock, {
            background: msg.block_color || '#fff',
          });
        });
      });
      return;
    }
    if (!blockId || !containerId) {
      return;
    }
    if (msg.msg_type == 'response') {
      try {
        Object.values(logsContainerId).forEach(item => {
          edgelessRoot?.service.removeElement(item);
        });
      } catch (error) {
        console.log(error, 'errr');
      }
    }

    if (blockType == 'finished_flag') {
      socketInstance?.close();
      setSocketInstance(null);
    }
    let isContainerExist = edgelessRoot?.doc.getBlockById(containerId);
    let isBlockExist = edgelessRoot?.doc.getBlockById(blockId);
    if (blockType === 'log' && !isContainerExist) {
      const logContainerId = createBlockAtPosition(
        { x: originX, y: originY, w: 300, h: 150 },
        false,
        '',
        blockId
      );
      const imgblockId = edgelessRoot?.service.addBlock(
        'affine:image',
        {
          size: gifFile?.size,
        },
        logContainerId
      );
      const sourceId = await edgelessRoot?.doc.blobSync.set(gifFile);
      edgelessRoot?.doc.withoutTransact(() => {
        edgelessRoot.service.updateElement(imgblockId, {
          sourceId,
          width: 100,
          height: 100,
        });
      });

      const strBlockId = edgelessRoot?.doc?.addBlock(
        'affine:paragraph',
        { type: null },
        logContainerId
      );
      setTimeout(() => {
        let target = edgelessRoot.querySelector(
          `[data-block-id="${strBlockId}"]`
        );
        if (target) {
          target.querySelector('[data-v-text="true"]').innerText =
            msg.stream_str;
        }
      }, 100);

      logsContainerId[containerId] = logContainerId;
      if (isContainerExist) {
        try {
          edgelessRoot?.service.removeElement(logContainerId);
          delete logsContainerId[containerId];
        } catch (error) {
          console.log(error);
        }
      }
      if (firstTime) {
        firstTime = false;
        edgelessRoot?.service.zoomToFit();
      }
      console.log('添加log');
      return;
    }
    if (blockType === 'log' && isContainerExist) {
      try {
        edgelessRoot?.service.removeElement(logsContainerId[containerId]);
        delete logsContainerId[containerId];
      } catch (error) {
        console.log(error);
      }
      console.log('删除log');
    }
    if (
      !isContainerExist &&
      blockType !== 'log' &&
      blockType !== 'finished_flag'
    ) {
      const color_idx = Math.floor((originY - 200) / 800) % colorList.length;
      createBlockAtPosition(
        { x: originX, y: originY, w, h },
        collapse,
        '',
        containerId,
        actionName,
        colorList[color_idx]
      );
      if (
        upstreamContainerId !== null &&
        typeof upstreamContainerId === 'object'
      ) {
        upstreamContainerId.forEach(upId => {
          generateConnector(upId, containerId);
        });
      }
      if (
        upstreamContainerId !== '' &&
        typeof upstreamContainerId === 'string'
      ) {
        generateConnector(upstreamContainerId, containerId);
      }

      try {
        edgelessRoot?.service.removeElement(logsContainerId[containerId]);
        delete logsContainerId[containerId];
      } catch (error) {}
    }
    if (isContainerExist) {
      try {
        edgelessRoot?.doc.removeElement(logsContainerId[containerId]);
        delete logsContainerId[containerId];
      } catch (error) {}
    }
    if (!isBlockExist && blockType !== 'log' && blockType !== 'finished_flag') {
      if (blockType === 'markdown') {
        await insertBelow(
          edgelessRef.current?.host,
          msg.stream_str,
          containerId
        );
      }
      let newBlockId: string;
      if (blockType === 'code') {
        newBlockId = edgelessRoot?.doc.addBlock(
          'affine:code',
          {
            text: new Text(msg?.stream_str),
            language: 'Plain Text',
            wrap: false,
            caption: '',
          },
          containerId
        );
      }
      if (blockType === 'text') {
        newBlockId = edgelessRoot?.service.addBlock(
          'affine:paragraph',
          {
            type: 'text',
            text: new Text(msg?.stream_str),
          },
          containerId
        );

        // requestAnimationFrame(() => {
        //   edgelessRoot?.doc.transact(() => {
        //     const targetBlock = edgelessRoot?.doc.getBlockById(
        //       newBlockId
        //     ) as NoteBlockModel;
        //     edgelessRoot?.doc.updateBlock(targetBlock, {
        //       text: new Y.Text(msg?.stream_str),
        //     });
        //   });
        // });

        // let target = edgelessRoot.querySelector(
        //   `[data-block-id="${newBlockId}"]`
        // );
        // if (target) {
        //   target.querySelector('[data-v-text="true"]').innerText =
        //     BlockIds[blockId]?.msg + msg?.stream_str;
        // }
      }
      if (blockType === 'img') {
        try {
          let file = await fetchFileFromUrl(msg.img_url);
          newBlockId = edgelessRoot.service.addBlock(
            'affine:image',
            {
              size: file.size,
            },
            containerId
          );
          const sourceId = await edgelessRoot?.doc.blobSync.set(file);
          const imageSize = await readImageSize(file);
          edgelessRoot?.doc.withoutTransact(() => {
            edgelessRoot.service.updateElement(newBlockId, {
              sourceId,
              ...imageSize,
            });
          });
        } catch (error) {
          console.log(error, 'imgerror');
        }
      }
      if (blockType === 'todo_list') {
        let todoItems = JSON.parse(msg.stream_str);
        todoItems.forEach(todo => {
          newBlockId = edgelessRoot?.doc.addBlock(
            'affine:list',
            {
              type: 'todo',
              text: new Text(todo.label),
              checked: todo.checked, // 可选，设置初始状态为未选中
            },
            containerId
          );
        });
      }
      if (blockType === 'attachment') {
        try {
          newBlockId = edgelessRoot.service.addBlock(
            'affine:attachment',
            {
              name: detail?.filename,
              size: detail?.file_size,
              type: detail?.file_type,
            },
            containerId
          );
          // 将 Base64 字符串转换为 Uint8Array
          const byteArray = base64ToUint8Array(detail?.file_data);
          // 创建Blob对象
          const blob = new Blob([byteArray], { type: detail?.file_type });

          const file = new File([blob], detail?.filename, {
            type: detail?.file_type,
          });
          const fileSourceId = await edgelessRoot?.doc.blobSync.set(file);
          console.log(fileSourceId, 'fileSourceId');
          edgelessRoot?.doc.withoutTransact(async () => {
            edgelessRoot.service.updateElement(newBlockId, {
              sourceId: fileSourceId,
            });
          });
        } catch (error) {}
      }
      if (blockType === 'table') {
        try {
          newBlockId = edgelessRoot?.doc.addBlock(
            'affine:database',
            {
              title: new Text(detail?.table_name),
            },
            containerId
          );
          const blockModel = edgelessRoot.doc.getBlock(newBlockId)?.model;
          detail?.columns.map(item => {
            addProperty(blockModel, 'end', {
              type: item.type,
              name: item.label,
              id: item.label,
              data: {
                options: item?.options || [],
              },
            });
          });
          detail?.datas.forEach(rowData => {
            const rowId = blockModel.doc.addBlock(
              'affine:paragraph',
              {},
              blockModel.id
            );
            detail.columns.forEach(({ label, type }) => {
              if (type === 'rich-text') {
                updateCell(blockModel, rowId, {
                  columnId: label,
                  value: new blockModel.doc.Text(rowData[label]),
                });
              } else {
                updateCell(blockModel, rowId, {
                  columnId: label,
                  value: rowData[label],
                });
              }
            });
          });
          const dataSource = new DatabaseBlockDataSource(blockModel);
          dataSource.viewManager.viewAdd('table');
          setTimeout(() => {}, 100);
        } catch (error) {}
      }
    } else {
      let targetBlock = edgelessRoot?.doc.getBlockById(blockId);
      if (targetBlock?.flavour === 'affine:paragraph') {
        let newMsg = targetBlock.text?.toString() + msg?.stream_str;
        requestAnimationFrame(() => {
          edgelessRoot?.doc.transact(() => {
            edgelessRoot?.doc.updateBlock(targetBlock, {
              text: new Text(newMsg),
            });
          });
        });
      }
    }
    setTimeout(processMessageQueue, DELAY);
  };

  const handleMessage = msg => {
    messageQueue.push(msg);
    processMessageQueue();
  };

  const { workspaceService } = useServices({
    WorkspaceService,
  });
  const workspace = workspaceService.workspace;

  const handleExportTemplate = async () => {
    let doc = Array.from(workspace.docCollection.docs.values())
      .filter(item => item.id === page.id)
      .map(doc => doc.getDoc());
    console.log(doc);
    const zip = await ZipTransformer.exportDocs(workspace.docCollection, doc);
    const url = URL.createObjectURL(zip);
    // download url
    const a = document.createElement('a');
    a.href = url;
    a.download = `${workspace.docCollection.meta.name}.zip`;
    a.click();
    URL.revokeObjectURL(url);
  };

  function addAIChatBlock(
    host: EditorHost,
    messages: ChatMessage[],
    sessionId: string,
    viewportCenter: { x: number; y: number },
    index: string
  ) {
    if (!messages.length || !sessionId) {
      return;
    }

    const { doc } = host;
    const surfaceBlock = doc
      .getBlocks()
      .find(block => block.flavour === 'affine:surface');
    if (!surfaceBlock) {
      return;
    }

    // Add AI chat block to the center of the viewport
    const width = 300; // AI_CHAT_BLOCK_WIDTH = 300
    const height = 320; // AI_CHAT_BLOCK_HEIGHT = 320
    const x = viewportCenter.x - width / 2;
    const y = viewportCenter.y - height / 2;
    const bound = new Bound(x, y, width, height);
    const aiChatBlockId = doc.addBlock(
      'affine:embed-ai-chat' as keyof BlockSuite.BlockModels,
      {
        xywh: bound.serialize(),
        messages: JSON.stringify(messages),
        index,
        sessionId,
        rootWorkspaceId: doc.collection.id,
        rootDocId: doc.id,
      },
      surfaceBlock.id
    );

    return aiChatBlockId;
  }

  const handleBtnClick = async () => {
    let block = edgelessRoot?.doc.getBlockById('euGJnYbFMHTDmsrA9MBd6');

    // debugger;
    // const newSessionId = await AIProvider.forkChat?.({
    //   workspaceId: edgelessRoot?.doc.collection.id,
    //   docId: edgelessRoot?.doc.id,
    //   sessionId: '79426d6f-49ef-424e-aa9c-7ceb5866b636',
    //   latestMessageId: '9b013ab6-a7c1-478f-b137-6daa7f91e4d3',
    // });
    // if (!newSessionId) {
    //   return false;
    // }
    // console.log(newSessionId, ' newSessionId');
    // // Get messages before the latest message
    // const messages = await constructRootChatBlockMessages(
    //   edgelessRoot?.doc,
    //   newSessionId
    // );
    // console.log(messages, 'messages');
    // // After switching to edgeless mode, the user can save the chat to a block
    // const blockId = addAIChatBlock(
    //   affineEditorContainerProxy.host,
    //   messages,
    //   newSessionId,
    //   { x: 100, y: 100 },
    //   '0'
    // );
    // if (!blockId) {
    //   return false;
    // }
    // console.log(blockId);
    // const telemetryService =
    //   affineEditorContainerProxy.host.std.getOptional(TelemetryProvider);
    // telemetryService?.track('CanvasElementAdded', {
    //   control: 'manually save',
    //   page: 'whiteboard editor',
    //   module: 'ai chat panel',
    //   segment: 'right sidebar',
    //   type: 'chat block',
    //   category: 'root',
    // });
    // let id = '1UGAh923Pd5JOomNnFvk7';
    // let messages = [
    //   {
    //     id: '251e80ea-1c14-4889-8c8c-e37a6c5151c6',
    //     role: 'user',
    //     content: '你是谁 ',
    //     createdAt: '2024-11-19T03:33:03.567Z',
    //     attachments: [],
    //     userId: '918608f1-605f-4701-8fdf-fa5bdea8d3ba',
    //     userName: 'admin',
    //     avatarUrl: '',
    //   },
    //   {
    //     id: '709c668a-7f97-49d3-9f46-e78b9f0b86cc',
    //     role: 'assistant',
    //     content:
    //       '1111我是 Noumena-Muse AI，一名专业且幽默的营销协作平台的智能助手，由 OpenAI 和 Noumena 最新自研的 GPT 模型驱动。我可以帮助你使用 Muse 进行信息洞察、文档编写与协作、图表绘制，并善用这些功能。我的使命是尽最大努力协助你完成各种任务。如果你有任何问题或需要帮助，请随时告诉我！',
    //     createdAt: '2024-11-19T03:33:06.438Z',
    //     attachments: [],
    //   },
    //   {
    //     id: '0b27cc78-473b-4a04-945c-d68de386159b',
    //     role: 'user',
    //     content: '最新测试',
    //     createdAt: '2024-11-20T04:40:08.023Z',
    //     attachments: [],
    //     userId: '918608f1-605f-4701-8fdf-fa5bdea8d3ba',
    //     userName: 'admin',
    //     avatarUrl: '',
    //   },
    //   {
    //     id: '216a49ea-05f6-4dda-a66f-c73a732aefc5',
    //     role: 'assistant',
    //     content:
    //       '1234567890当然，我可以帮助你进行最新测试。请告诉我你具体需要测试什么内容或功能？例如是 Muse 的某个特定功能，还是需要进行某种数据分析或文档编写测试？提供更多详细信息将有助于我更好地帮助你。',
    //     createdAt: '2024-11-20T04:40:10.488Z',
    //     attachments: [],
    //   },
    // ];
    // const newSessionId = await AIProvider.forkChat?.({
    //   workspaceId: edgelessRoot?.doc.collection.id,
    //   docId: edgelessRoot?.doc.id,
    //   sessionId: 'bc532a11-1162-4d0c-8873-43afbe9caac4',
    //   latestMessageId: 'b4f6352c-d52f-48ba-b03f-6b68370685b0',
    // });
    // if (!newSessionId) {
    //   return false;
    // }
    // console.log(newSessionId, 'newSessionId');
    // // Get messages before the latest message
    // const messages = await constructRootChatBlockMessages(
    //   edgelessRoot?.doc,
    //   newSessionId
    // );
    // edgelessRoot?.doc.transact(() => {
    //   const targetBlock = edgelessRoot?.doc.getBlockById(id) as NoteBlockModel;
    //   debugger;
    //   edgelessRoot?.doc.updateBlock(targetBlock, {
    //     messages: JSON.stringify(messages),
    //     sessionId: targetBlock.sessionId,
    //   });
    // });
    // const surfaceBlock = edgelessRoot?.doc
    //   .getBlocks()
    //   .find(block => block.flavour === 'affine:surface');
    // if (!surfaceBlock) {
    //   return;
    // }
    // const aiChatBlockId = edgelessRoot?.doc.addBlock(
    //   'affine:embed-ai-chat' as keyof BlockSuite.BlockModels,
    //   {
    //     xywh: `[${100},${100},${300},${320}]`,
    //     messages: JSON.stringify([
    //       {
    //         id: '1',
    //         content: '提问',
    //         role: 'user',
    //         createdAt: '',
    //       },
    //       {
    //         id: '2',
    //         content: '回答',
    //         role: 'assistant',
    //         createdAt: '',
    //       },
    //     ]),
    //     sessionId: '79426d6f-49ef-424e-aa9c-7ceb5866b636',
    //     rootWorkspaceId: edgelessRoot?.doc.collection.id,
    //     rootDocId: edgelessRoot?.doc.id,
    //   },
    //   surfaceBlock.id
    // );
    // console.log(aiChatBlockId, 'aiChatBlockId');
  };

  return (
    <div
      data-testid={`editor-${page.id}`}
      className={clsx(
        `editor-wrapper ${mode}-mode`,
        styles.docEditorRoot,
        className
      )}
      data-affine-editor-container
      style={style}
      ref={rootRef}
    >
      {mode === 'page' ? (
        <BlocksuiteDocEditor
          shared={shared}
          page={page}
          ref={docRef}
          titleRef={docTitleRef}
          onClickBlank={handleClickPageModeBlank}
        />
      ) : (
        <>
          <div
            style={{ marginRight: '53px' }}
            className={clsx(styles.floatBtn)}
          >
            <button
              className={aiIslandBtn}
              data-testid="ai-island"
              onClick={() => {
                handleExportTemplate();
              }}
            >
              导出模板
            </button>
          </div>
          <div className={clsx(styles.floatBtn)}>
            <button
              className={aiIslandBtn}
              data-testid="ai-island"
              onClick={() => {
                handleBtnClick();
              }}
            >
              选择模板
            </button>
          </div>
          <BlocksuiteEdgelessEditor
            shared={shared}
            page={page}
            ref={edgelessRef}
          />
        </>
      )}

      <Modal
        width={'70%'}
        title="生成进度"
        open={loadingOpen}
        onCancel={() => {
          setLoadingOpen(false);
        }}
        footer={null}
        closeIcon={null}
        maskClosable={false}
      >
        <Paragraph
          style={{
            whiteSpace: 'break-spaces',
            maxHeight: 600,
            overflow: 'auto',
          }}
        >
          {loadingStr}
          <div ref={messagesEndRef} />
        </Paragraph>
      </Modal>

      <CardDetailModal
        open={modalOpen}
        setOpen={setModalOpen}
        containerId={showId}
        pageId={page.id}
      ></CardDetailModal>
      <NotesDetailModal
        open={notesModalOpen}
        setOpen={setNotesModalOpen}
        containerId={showId}
        pageId={page.id}
        edgelessRoot={edgelessRoot}
      ></NotesDetailModal>
      <EditModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        containerId={showId}
        pageId={page.id}
      ></EditModal>
    </div>
  );
});

import { BlockViewExtension, type ExtensionType } from '@blocksuite/block-std';
import { literal } from 'lit/static-html.js';
import { EmbedHtmlCustomBlockComponent } from './embed-html-block';
import { EmbedEdgelessHtmlCustomBlockComponent } from './embed-edgeless-html-block';

export const EmbedCustomHtmlBlockSpec: ExtensionType[] = [
  BlockViewExtension('affine:embed-html-custom', model => {
    return model.parent?.flavour === 'affine:surface'
      ? literal`affine-embed-edgeless-html-block-custom`
      : literal`affine-embed-html-block-custom`;
  }),
];

export function effects() {
  customElements.define(
    'affine-embed-html-block-custom',
    EmbedHtmlCustomBlockComponent
  );
  customElements.define(
    'affine-embed-edgeless-html-block-custom',
    EmbedEdgelessHtmlCustomBlockComponent
  );
}
